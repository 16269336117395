@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $isOnlyWoff: false ) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;

    @if $isOnlyWoff == true {
      src: url('#{$file-path}.woff');
      src: url('#{$file-path}.woff') format('woff'),
      url('#{$file-path}.woff2') format('woff2');
    } @else {
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
      url('#{$file-path}.woff') format('woff'),
      url('#{$file-path}.woff2') format('woff2'),
      url('#{$file-path}.ttf') format('truetype');
    }


  }
}
