.Span_s1a8gko7{font-family:inherit;font-size:inherit;line-height:inherit;color:var(--s1a8gko7-0);}.Span_s1a8gko7[aria-disabled]{color:#DEDEDE;}
.TypographyBase_t102nrcx{font-family:montserrat,sans-serif;font-size:.88rem;line-height:200%;font-weight:500;margin:0;color:var(--t102nrcx-0);margin-bottom:1em;}.TypographyBase_t102nrcx:hover{-webkit-line-clamp:initial;}.TypographyBase_t102nrcx > .Span_s1a8gko7 + .cssIcon_cc7xf1o{margin-left:6px;}.TypographyBase_t102nrcx[aria-disabled]{color:#DEDEDE;}
.Heading1_h1fcqzt4{font-family:montserrat,sans-serif;font-size:1.62rem;line-height:131%;font-weight:bold;margin:0;margin-bottom:40px;}
.Heading2_hl3waee{font-family:montserrat,sans-serif;font-size:1.25rem;line-height:170%;font-weight:500;margin:0;margin-bottom:24px;}
.Heading3_h1bahcjt{font-family:montserrat,sans-serif;font-size:1rem;line-height:150%;font-weight:bold;margin:0 0 16px 0;}
.Heading5_h1m9r6p9{font-family:montserrat,sans-serif;font-size:0.88rem;line-height:200%;font-weight:bold;margin:0;margin-bottom:10px;}
.Heading6_hpo3v84{font-family:montserrat,sans-serif;font-size:1.13rem;line-height:156%;font-weight:500;margin:0;}
.Paragraph16_payptdg.TypographyBase_t102nrcx{font-family:montserrat,sans-serif;font-size:1rem;line-height:187%;font-weight:500;margin:0;}

.Paragraph13_pi6tduq.TypographyBase_t102nrcx{font-family:montserrat,sans-serif;font-size:.80rem;line-height:150%;font-weight:500;margin:0;}
.Paragraph12_p12g4ckv.TypographyBase_t102nrcx{font-family:montserrat,sans-serif;font-size:.75rem;line-height:200%;font-weight:500;margin:0;}
.Paragraph10_p1qlbh2y.TypographyBase_t102nrcx{font-family:montserrat,sans-serif;font-size:.63rem;line-height:140%;font-weight:500;margin:0;}
.NoWrap_n1nwewif.Span_s1a8gko7{white-space:nowrap;-webkit-flex-wrap:nowrap;-ms-flex-wrap:nowrap;flex-wrap:nowrap;}
.ErrorMessageDefault_e1feitb3.Paragraph12_p12g4ckv.TypographyBase_t102nrcx{color:#FF003D;margin-top:6px;}.ErrorMessageDefault_e1feitb3.Paragraph12_p12g4ckv.TypographyBase_t102nrcx:first-child{margin-top:0;}
.ErrorMessageField_e1o5qotj.ErrorMessageDefault_e1feitb3.Paragraph12_p12g4ckv.TypographyBase_t102nrcx{font-family:montserrat,sans-serif;font-size:.75rem;line-height:200%;font-weight:500;margin:0;margin-top:4px;word-break:break-word;margin-bottom:0;width:100%;}
.ErrorMessageContainer_e1clktta{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;}.ErrorMessageContainer_e1clktta .ErrorMessageField_e1o5qotj{margin:0 0 6px 0;}
.InitialsWord_ig06jcn.Span_s1a8gko7{margin-right:2px;}

.InitialsBody_iqneph1.Span_s1a8gko7{position:relative;}.InitialsBody_iqneph1.Span_s1a8gko7:after{content:" ";position:relative;}@media (max-width:992px){.InitialsBody_iqneph1.Span_s1a8gko7{display:none;}}@media (max-width:768px){.InitialsBody_iqneph1.Span_s1a8gko7{display:initial;}}@media (max-width:576px){.InitialsBody_iqneph1.Span_s1a8gko7{display:none;}}
.Typography404_t1j29rm9{font-family:montserrat,sans-serif;font-size:1.62rem;line-height:131%;font-weight:bold;margin:0;font-size:146px;color:#FECC00;white-space:nowrap;}@media (max-width:576px){.Typography404_t1j29rm9{font-size:96px;}}
